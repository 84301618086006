export default {
  darkMode: "Dark mode",
  helpdeskTileMessage: "New helpdesks today",
  messageTile: {
    singular: "new message",
    plural: "new messages"
  },
  limetypeInspector: {
    labelFieldtype: "Only show fields that containt a field type of...",
    labelObject: "Object",
    title: "Browse the Lime schema...",
    properties: "Properties:",
  },
  menu: {
    coworkers: "Coworkers (en)",
    deals: "Show deals (en)",
    helpdeskShow: "Show tickets (en)",
    helpdeskCreate: "Create ticket (en)",
    pagination: "Paginering (en)",
    form: "Form #1 (en)",
    grouped: "Grouped (en)",
  },
  auth: {
    selectEntityTitle: "Select one of your children to view information for", // title text on entity page
    selectEntityButtonText: "Continue", // button text for "submit"
    cancelSwitchEntity: "Go back" // "cancel" button text
  },
  nav: {
    switchEntity: "Switch to another child", // menu item text
  },
}