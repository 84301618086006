import { render, staticRenderFns } from "./BankId.vue?vue&type=template&id=597ecfd2&scoped=true&"
import script from "./BankId.vue?vue&type=script&lang=js&"
export * from "./BankId.vue?vue&type=script&lang=js&"
import style0 from "./BankId.vue?vue&type=style&index=0&id=597ecfd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "597ecfd2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ff-template-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('597ecfd2')) {
      api.createRecord('597ecfd2', component.options)
    } else {
      api.reload('597ecfd2', component.options)
    }
    module.hot.accept("./BankId.vue?vue&type=template&id=597ecfd2&scoped=true&", function () {
      api.rerender('597ecfd2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/auth/methods/wrapped/BankId.vue"
export default component.exports